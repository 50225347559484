<template>
    <b-table
        :items="trainees.data"
        :fields="trainees.fields"
        :sort-by.sync="trainees.sortBy"
        :sort-desc.sync="trainees.sortDesc"
        :per-page="10"
        :busy="isBusy"
        responsive
        hover
        striped
    >
        <template #cell(customer)="row">
            <div class="d-flex flex-column justify-content-around align-items-center" v-if="row.item.customer">
                {{ row.item.customer.name }}
                <span class="text-muted"> ( {{ row.item.customer.code }} ) </span>
            </div>
        </template>
        <template #cell(Action)="row">
            <div class="d-flex flex-column justify-content-around align-items-center">
                <button
                    class="btn btn-clean btn-sm btn-primary btn-icon"
                    @click="selectTrainee(row.item)"
                    v-b-tooltip.hover.left="'Choisir cet utilisateur'"
                >
                    <i class="fas fa-user-cog"></i>
                </button>
            </div>
        </template>
    </b-table>
</template>

<script>
export default {
    props: ['trainees', 'isBusy']
};
</script>
